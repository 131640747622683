import React, { useState, FormEvent, useContext, useEffect } from "react"
import Icon from "@mdi/react"
import { mdiHome, mdiEmail, mdiPhone, mdiWhatsapp } from "@mdi/js"
import moment from "moment"
import { API } from "../utils/constants"
import Axios from "axios"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { useTranslation } from "react-i18next"
import { GlobalStateContext } from "../context/GlobalContextProvider"

const Footer = () => {
  const { t, i18n } = useTranslation()
  const { language } = useContext(GlobalStateContext)
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  })
  const [captchaSolved, setCaptchaSolved] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  const sendEmail = (e: FormEvent) => {
    e.preventDefault()
    const api = `${API}/kMail.php`
    Axios.post(api, JSON.stringify(formData)).then(res => {
      if (res.status === 200) {
        toast.success(t("message.sent"))
      }
    })
  }

  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">{t("menu.contact")}</h2>
        <p className="text-white">{t("contact.description")}</p>
        <form onSubmit={sendEmail}>
          <div className="fields">
            <div className="field">
              <label htmlFor="name">{t("contact.name")}</label>
              <input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </div>
            <div className="field">
              <label htmlFor="phone">{t("contact.phone")}</label>
              <input
                required
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={e =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
            </div>
            <div className="field">
              <label htmlFor="email">{t("contact.email")}</label>
              <input
                required
                type="email"
                name="email"
                value={formData.email}
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div className="field">
              <label htmlFor="message">{t("contact.message")}</label>
              <textarea
                required
                name="message"
                rows={4}
                value={formData.message}
                onChange={e =>
                  setFormData({ ...formData, message: e.target.value })
                }
              ></textarea>
            </div>
            <div style={{ marginTop: 15 }} className="field">
              <ReCAPTCHA
                sitekey="6Ld5YMIZAAAAABWqGE71ziv9xmDv6sa2ylow0oDN"
                theme="dark"
                onChange={value => {
                  if (value) setCaptchaSolved(true)
                }}
                onExpired={() => setCaptchaSolved(false)}
              />
            </div>
          </div>
          <ul className="actions">
            <li>
              <button disabled={!captchaSolved} type="submit">
                {t("button.send")}
              </button>
            </li>
          </ul>
        </form>
        <div className="contact">
          <div className="row">
            <div className="col-2">
              <Icon path={mdiHome} size={1} color="white" />
            </div>
            <div className="col-10 text-light">
              J Claro García #142
              <br />
              Colonia Magisterial
              <br />
              Villahermosa, Tabasco, México <br />
              C.P. 86040
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-2">
              <Icon path={mdiPhone} size={1} color="white" />
            </div>
            <div className="col-10 text-light">
              <a href="tel:9933193640">993 319 3640</a>
              <br />
              <a href="tel:9933193726">993 319 3726</a>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-2">
              <Icon path={mdiWhatsapp} size={1} color="white" />
            </div>
            <div className="col-10 text-light">
              <a href="https://wa.link/ij4en8">993 397 9777</a>
              <br />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-2">
              <Icon path={mdiEmail} size={1} color="white" />
            </div>
            <div className="col-10 text-light">
              <a
                className="text-white"
                href="mailto:sammy.mena@sisoluciones.com.mx"
              >
                sammy.mena@sisoluciones.com.mx
              </a>
            </div>
          </div>
        </div>

        <ul className="copyright">
          <li>
            &copy; {moment().year()} {t("footer.message1")}{" "}
            <a href="https://nodeaxan.com/">NodeAxan</a>.
          </li>
          <li>
            {t("footer.design")}:{" "}
            <a rel="noreferrer" target="_blank" href="http://html5up.net">
              HTML5 UP
            </a>{" "}
            {t("footer.message2")}
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Footer
