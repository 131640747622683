import React, { useState, useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import { Modal } from 'react-bootstrap';
import { appColors, IS_SERVICE_AVAILABLE } from '../utils/constants';
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';
import GlobalContextProvider from '../context/GlobalContextProvider';
import { GlobalDispatchContext, GlobalStateContext } from '../context/GlobalContextProvider';
import { useTranslation } from 'react-i18next';


const Navigation = () => {
    const [isShown, setIsShown] = useState(false);
    const [navbarBackground, setNavbarBackground] = useState(false);
    const dispatch = useContext(GlobalDispatchContext);
    const { language } = useContext(GlobalStateContext);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);
    
    const languageButtonStyles: React.CSSProperties = {
        padding: 0,
        margin: 0,
        backgroundImage: language === "es" ? `url(${require('./../assets/images/united-states-flag.jpg')})` : `url(${require('./../assets/images/mexico-flag.svg')})`,
        backgroundSize: "50px 30px",
        backgroundPosition: "center",
        width: 30,
        height: 30,
        borderRadius: "100%"
    }
    
    const handleScroll = () => {
        if (window.scrollY > 10) {
            setNavbarBackground(true);
            return;
        }
        setNavbarBackground(false);
    }

    const toggleLanguage = () => {
        const newLanguage = language === "es" ? "en" : "es";
        localStorage.setItem("language", newLanguage);
        dispatch({type: 'CHANGE_LANGUAGE', payload: newLanguage})
    }

    useEffect(() => {
        if (!IS_SERVICE_AVAILABLE) navigate('/unavailable');
        window.addEventListener('scroll', handleScroll, { passive: true })
    
        const cleanUp = () => {
            window.removeEventListener('scroll', handleScroll);
        }
        return cleanUp;
    }, []);

    return (
        <GlobalContextProvider>
            <header>
                <nav style={{display: "flex", justifyContent: "space-between", position: "fixed", width: "100%", transitionDuration: "0.5s", zIndex: 1}} className={navbarBackground ? "bg-dark" : ""}>
                    <div style={{padding: 5, paddingLeft: 20}}>
                        <img src={require('./../assets/images/isologo.png')} alt="SI Soluciones Logo" style={{width: 50, cursor: "pointer"}} onClick={() => navigate("/")} />
                    </div>
                    <div>
                        <button onClick={toggleLanguage} style={languageButtonStyles}></button>
                        <button onClick={() => setIsShown(true)} style={{boxShadow: "none"}}><span><Icon size={1} path={mdiMenu} /></span></button>
                    </div>
                </nav>

                <Modal centered show={isShown} onHide={() => setIsShown(false)}>
                    <Modal.Body style={{backgroundColor: appColors.primary}}>
                        <div className="d-flex justify-content-end">
                            <button onClick={() => setIsShown(false)} style={{boxShadow: "none", padding: 0, margin: 0}}><img style={{width: 40}} src={require('./../assets/images/close.svg')} /></button>
                        </div>
                        <h2 className="text-center">{t('menu')}</h2>
                        <hr style={{border: "1px solid #4F5561"}} />
                        <ul className="links">
                            <li><Link to="/">{t('menu.home')}</Link></li>
                            <li><Link to="/about">{t('menu.about')}</Link></li>
                            <li><Link to="/services">{t('menu.services')}</Link></li>
                            <li><Link to="/contact">{t('menu.contact')}</Link></li>
                        </ul>
                    </Modal.Body>
                </Modal>
            </header>
        </GlobalContextProvider>
    );
};

export default Navigation;